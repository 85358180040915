export class ValidationError extends Error {
  payload;
  constructor(message, details, displayedMessage) {
    super(message)
    this.name = 'ValidationError'
    this.details = details 
    this.displayedMessage = displayedMessage
  }
}

export class RequestError extends Error {
  constructor(request, payload = null) {
    super('Cannot obtain request or data from endpoint')
    this.name = 'RequestError'
    this.details = request
    this.payload = payload
  }
}

export class SearchError extends Error {
  payload;
  constructor(message, payload) {
    super(message)
    this.name = 'SearchError'
    this.payload = payload 
  }
}