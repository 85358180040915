import BackendApi from '@/services/backend.service'
import { ValidationError, RequestError } from '@/services/errorHandler.service'
import CustomException from '@/services/customException'

const state = {
  tags: null,
  isFetchingTags: false,
  isUpdatingTicketStatus: false,
  isUploadingAlias: false,
  isTaggingAPhone: false,
  isUpdatingPhonetags: false
}

const getters = {
  formattedPickerTags: (state) => {
    const groupedTags = []

    if (!state.tags) return null

    for (let i = 0; i < state.tags.length; i += 3) {
      const group = state.tags.slice(i, i + 3)

      groupedTags.push(group)
    }

    return groupedTags
  }
}

const mutations = {
  SET_TAGS(state, tags) {
    state.tags = tags
  },
  SET_IS_FETCHING_TAGS(state, isFetchingTags) {
    state.isFetchingTags = isFetchingTags
  },
  SET_IS_UPDATING_TICKET_STATUS(state, isUpdatingTicketStatus) {
    state.isUpdatingTicketStatus = isUpdatingTicketStatus
  },
  SET_IS_UPLOADING_ALIAS(state, isUploadingAlias) {
    state.isUploadingAlias = isUploadingAlias
  },
  SET_IS_TAGGING_A_PHONE(state, isTaggingAPhone) {
    state.isTaggingAPhone = isTaggingAPhone
  },
  SET_IS_UPDATING_PHONE_TAGS(state, isUpdatingPhonetags) {
    state.isUpdatingPhonetags = isUpdatingPhonetags
  }
}

const actions = {
  async updateTicketStatus({ commit, dispatch }, { ticket, newStatusName, vueContext }) {
    const nameToStatusId = { 'pending': 1, 'in_attention': 2, 'success': 3, 'failed': 4 }
    const statusIdToName = { 1: 'pending', 2: 'in_attention', 3: 'success', 4: 'failed' }
    
    commit('SET_IS_UPDATING_TICKET_STATUS', true)
    try {
      const response = await BackendApi.post('/chat/update_ticket_status', { ticket_id: ticket.id, status_id: nameToStatusId[newStatusName] })

      if (!response.data.success) throw new Error('No se pudo obtener la confirmación de cambio de estado')

      // Eliminar : 
      commit('ticketsDrawer/DELETE_TICKET', ticket,  { root: true })
      commit('ticketsDrawer/UPDATE_STAGE_COUNT', { stage_name: statusIdToName[ticket.status_id], operation: -1 },  { root: true })

      if (newStatusName === 'derived') return

      // Agregar :
      ticket.status_id = nameToStatusId[newStatusName]
      ticket.is_pinned = 0

      commit('ticketsDrawer/ADD_TICKET', ticket, { root: true })
      commit('ticketsDrawer/UPDATE_STAGE_COUNT', { stage_name: newStatusName, operation: +1 },  { root: true })

      // Menú abierto
      if (newStatusName === 'in_attention') commit('ticketsDrawer/SET_STAGE_EXPANDED_ID', nameToStatusId[newStatusName], { root: true })
      commit('SET_IS_UPDATING_TICKET_STATUS', false)

      return true 
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
      commit('SET_IS_UPDATING_TICKET_STATUS', false)

      return false
    }
  },
  deleteTicket({ commit }, ticket) {
    commit('DELETE_TICKET', ticket)

    return ticket
  },
  async getTags({ commit, rootState }, vueContext) {
    commit('SET_IS_FETCHING_TAGS', true)
    try {
      const response = await BackendApi.post('/chat/workgroup_tags', { workgroup_id: rootState.ticketsDrawer.workgroupAgent.workgroup_id })

      if (!response || !response.data || !response.data.data) {
        throw new ValidationError('No se pudo obtener la data del backend', { response })
      }
      const tags = response.data.data

      commit('SET_TAGS', tags)
      commit('SET_IS_FETCHING_TAGS', false)
    } catch (error) {
      commit('SET_IS_FETCHING_TAGS', false)
      CustomException.handleError({ error }, vueContext)
    }
  },
  async addTag({ dispatch, rootState }, { vueContext, tag }) {
    const workgroup_id = rootState.ticketsDrawer.workgroupAgent.workgroup_id
    const { tag_name, tag_description, selectedTagColor  } = tag

    try {
      if (!tag_name || !selectedTagColor) {
        throw new ValidationError('Parámetros faltantes', { tag_name, tag_description, selectedTagColor })
      }

      const response = await BackendApi.post('/chat/add_tag', { workgroup_id, tag_name, tag_description, tag_color_id: selectedTagColor.color_id })

      if (!response?.data?.success) {
        throw new ValidationError('No se pudo añadir la etiqueta', { response })
      }

      dispatch('getTags')
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  async editTag({ dispatch }, data, vueContext) {
    const { tag_id, tag_name, tag_description, tag_color_id } = data

    try {
      if (!tag_id || !tag_name || !tag_color_id) {
        throw new ValidationError('Faltan parámetros', { tag_id, tag_name, tag_description, tag_color_id })
      }

      const response = await BackendApi.post('/chat/edit_tag', data)

      if (!response?.data?.success) {
        throw new ValidationError('No se pudo editar la etiqueta', { response })
      }

      dispatch('ticketsDrawer/fetchTicketsDrawer', { vueContext }, { root: true })
      dispatch('getTags')
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  async deleteTag({ dispatch }, { tag_id, vueContext }) {
    try {
      if (!tag_id) throw new ValidationError('Parámetros faltantes', { tag_id })
  
      const response = await BackendApi.post('/chat/delete_tag', { tag_id })

      if (!response?.data?.success) {
        throw new ValidationError('No se logró borrar la etiqueta', { response })
      }
      
      dispatch('ticketsDrawer/fetchTicketsDrawer', { vueContext }, { root: true })
      dispatch('getTags')
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },  
  async updatePhoneTags({ commit }, { vueContext, oldTagPhonesIds, newTagsIds, ticket }) {
    commit('SET_IS_UPDATING_PHONE_TAGS', true)
    try {
      const response = await BackendApi.post('/chat/phone_tags', { oldTagPhonesIds, newTagsIds, phone_id: ticket.client.phone_id })

      if (!response?.data?.success) { 
        throw new RequestError(response)
      }

      commit('ticketsDrawer/UPDATE_DRAWER_TICKET_TAGS', { newTagsIds, ticket, tags: state.tags }, { root: true })
      commit('SET_IS_UPDATING_PHONE_TAGS', false)
    } catch (error) {
      commit('SET_IS_UPDATING_PHONE_TAGS', false)
      CustomException.handleError({ error }, vueContext)
    }
  },
  async updateAlias({ commit, dispatch }, { vueContext, ticket, alias }) {
    commit('SET_IS_UPLOADING_ALIAS', true)
    try {
      const phone_id = ticket.client.phone_id
      const response = await BackendApi.post('/chat/update_phone_company_alias', { alias_name: alias, phone_id })

      if (!response?.data?.success || !response?.data?.data?.id) { 
        throw new ValidationError('Cambio de alias rechazado por el backend', { response, sucess:response?.data?.success })
      }
      
      dispatch('ticketsDrawer/UPDATE_DRAWER_TIKCET_ALIAS', { 
        new_alias: alias,
        new_alias_id: response.data.data.id,
        ticket }, 
      { root: true })

      commit('SET_IS_UPLOADING_ALIAS', false)
    } catch (error) {
      CustomException.handleError({ error }, vueContext)
    }
  },
  async asingTagToPhone({ commit }, { vueContext, ticket }) {
    commit('SET_IS_TAGGING_A_PHONE', true)
    let current_tags = []
  
    if (ticket.tags.lenght > 0) {
      current_tags = ticket.tags.map((tag) => tag.tag_id)
    }
  
    const payload = {
      phone_id: this.ticket.client.phone_id,
      current_tags: current_tags,
      new_tags: this.selected_tags
    }
  
    try {
      // Await the result of the asynchronous API call
      const response = await BackendApi.post('/chat/set_tag_conversation', payload)
      
      const aviable_tags = this.tags.current_tags
      const selected_tags_info = aviable_tags.filter((tag) => this.selected_tags.includes(tag.tag_id))
  
      this.$store.commit({
        type: 'setConversationtags',
        stage_index: this.stageIndex,
        conversation_index: this.index,
        setted_tags: selected_tags_info
      })
  
      // Stop the loader and close the dialog
      commit('SET_IS_TAGGING_A_PHONE', false)
    } catch (error) {
      commit('SET_IS_TAGGING_A_PHONE', false)
      CustomException.handleError({ error }, vueContext)
    }
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
