import * as Sentry from '@sentry/vue'
import { ValidationError } from '@/services/errorHandler.service'

class CustomException {
  static handleError({ rootMethod = null, rootPayload = null, displayError = true, error }, vueContext) {
    let toClientMessage

    if (displayError) {
      if (error instanceof ValidationError) {
        toClientMessage = error.displayedMessage
      } else {
        toClientMessage = error?.response?.data?.message ?? 'Un error inesparado ha ocurrido. Contacte con el administrador.'
      }

      if (vueContext?.$store ) vueContext.$store.dispatch('app/showError', { error: { message: toClientMessage } })
    }

    const componentName = vueContext?.$options?.name || 'Componente no registrado (falta "name" en el componente)'
    const errorDetails = {
      method: rootMethod,
      payload: rootPayload,
      toClientMessage,
      componentName,
      props: vueContext?.$options?.propsData,
      user: $cookies?.get('user') ?? 'Not identified User',
      url: window.location.href,
      userAgent: navigator.userAgent,
      timestamp: new Date().toISOString(),
      errorLog: {
        error: error,
        details: error instanceof ValidationError ? error.details : 'No hay detalles disponibles'
      }
    }

    console.error(error, error?.details ?? null)

    Sentry.withScope((scope) => {
      scope.setExtras(errorDetails)
      Sentry.captureException(error)
    })
  }
}

export default CustomException